import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';



import './stylesFilmes.css';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function CarouselCestas() {
 
  
  return (
    <>
      <div id='filmes'>

        <Swiper

          slidesPerView={4}
          spaceBetween={3}

          breakpoints={{
            640: {
              slidesPerView: 4,
              spaceBetween: 3,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 3,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 3,
            },
          }}
          loop={true}

          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="Swiper-Filmes mb-5"
          data-aos="fade-left" data-aos-duration="800"
        >

          <SwiperSlide>
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/01.jpg?t=2024-07-17T00%3A12%3A21.969Z" />
          </SwiperSlide>
          <SwiperSlide>
            
             
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/02.jpg?t=2024-07-17T00%3A15%3A57.875Z" />
           
          </SwiperSlide>
          <SwiperSlide>
            
             
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/03.jpg?t=2024-07-16T23%3A20%3A11.114Z" />
            
          </SwiperSlide>
          <SwiperSlide>
            
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/04.jpg?t=2024-07-16T23%3A20%3A11.114Z" />
            
          </SwiperSlide>
          <SwiperSlide>
           
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/05.jpg?t=2024-07-16T23%3A20%3A11.114Z" />
             
          </SwiperSlide>
          <SwiperSlide>
          
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/06.jpg?t=2024-07-17T00%3A04%3A40.873Z" />
          
          </SwiperSlide>
          <SwiperSlide>
         
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/07.jpg?t=2024-07-17T00%3A09%3A17.215Z" />
         
          </SwiperSlide>
          
          <SwiperSlide>
           
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/09.jpg?t=2024-07-16T23%3A20%3A11.114Z" />
           
          </SwiperSlide>
          <SwiperSlide>
          
                <img class="logo-filme " src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/cestas/10.jpg?t=2024-07-16T23%3A20%3A11.114Z" />
            
          </SwiperSlide>

        </Swiper>

      </div>

    </>
  )
}

function Vantagens() {
    return (
        <>


            <div class="container px-4 py-4 text-bella-black" id="icon-grid">
                <h2 class="pb-2 divider-orange text-center text-rosa">Porque Escolher Nossas Cestas</h2>

                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 g-4 py-5  ">

                    <div class="col d-flex align-items-start p-3 ">
                        <img className="me-3" src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/icones/estrela" width={30}/>
                        <div>
                            <h5 class="fw-bold mb-2 fs-4">Produtos de alta qualidade selecionados cuidadosamente</h5>
                        </div>
                    </div>
                    <div class="col d-flex align-items-start p-3 ">
                    <img className="me-3" src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/icones/estrela" width={30}/>

                        <div>
                            <h3 class="fw-bold mb-2 fs-4">Personalização completa de acordo com suas preferências</h3>
                        </div>
                    </div>
                    <div class="col d-flex align-items-start p-3 ">
                    <img className="me-3" src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/icones/estrela" width={30}/>

                        <div>
                            <h3 class="fw-bold mb-2 fs-4">Entrega rápida e segura em todo o DF</h3>
                        </div>
                    </div>
                    <div class="col d-flex align-items-start p-3 ">
                    <img className="me-3" src="https://uozxpkapjvicsytezmip.supabase.co/storage/v1/object/public/imagens/icones/estrela" width={30}/>

                        <div>
                            <h3 class="fw-bold mb-2 fs-4">Opções para todas as ocasiões</h3>
                        </div>
                    </div>


                </div>
            </div>

            <div class="container">


            </div>





        </>
    )
}
export default Vantagens;



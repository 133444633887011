import IconZap from './IconWp2.gif';

function ContatoWhatsapp(){

    return (
<>

<div class="position-relative">
  <div class=" bottom-0 end-0 mb-5 me-3 zindex-sticky fixed-bottom">
    <div class="float-end">
        <a href="https://api.whatsapp.com/send/?phone=5561994357523&text=Ol%C3%A1+%2ABella+Cesta%2A%21+Estou+com+algumas+dúvidas+pode+me+ajudar+%3F(site)&type=phone_number&app_absent=0" ><img src={IconZap} width={80} height={80}/></a>
    </div>
  </div>
</div>
</>
    )
}
export default ContatoWhatsapp;